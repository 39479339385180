import React from "react";

//Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  Grid,
  TextField,
} from "@material-ui/core";

import {
  StyleSheet,
} from "react-native";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import Snackbar from "@material-ui/core/Snackbar";
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { imgConnect } from "./assets";
import Alert from '@material-ui/lab/Alert';
import { webStyle } from "../../email-account-login/src/styles";

const StyledTextField = withStyles({
  root: {
    fontSize: "16",
    textTransform: 'lowercase',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontSize: "16",
      fontWeight: 500,
      width: "222.98px",
      height: "19px",
      color: "#44444f",
    },
    "& .MuiInputBase-input.MuiInput-input": 
    { fontFamily: "Montserrat" }
  },
})((props: any) => <TextField {...props} />);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    //Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} 
          style={webStyle.ContainerWidth}>
          <Grid container 
            style={webStyle.mainWrapper}>
            <Grid item xs={12} sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={webStyle.leftWrapper}>
                <Box 
                  sx={webStyle.innerLeftStyle}>
                  <Box style={webStyle.BoxImageStyle}>
                    <img src={imgConnect} style={webStyle.imgStyleLogin} />
                  </Box>
                  <Box style={{ 
                      paddingTop: '1em' 
                    }}>
                    <Typography
                      component={"h5"}
                      variant="h3"
                      style={webStyle.textStyleLeft}
                    >
                      Everything you need to{" "}
                      <span style={webStyle.fontColour}>maximize your HG homeschooling journey </span>
                      right at your fingertips
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} 
              sm={6} style={webStyle.mainInnerWrapper}>
              <Box sx={{...webStyle.rightWrapper, 
                  alignItems: 'left'
                }}>
                <Box sx={webStyle.innerRightStyleLogin}>
                  <Typography
                    variant="h6"
                    style={webStyle.textStyleRightlogin}
                    component={"h6"}
                  >
                    Forgot Your Password
                  </Typography>
                  <Typography style={{ color: 'rgb(85, 85, 85)', 
                      fontSize: '14px', 
                      fontWeight: 400, 
                      lineHeight: '22px' ,
                      fontFamily: "Montserrat",
                    }}>
                    Please enter the email address you’d like your password reset information to be received.
                  </Typography>
                  <Box>
                    <InputLabel 
                      style={{ ...webStyle.inputLabelStyleLogin, marginTop: '2em' }}>
                      {" "}
                      Email *
                    </InputLabel>
                    <StyledTextField
                      data-testid="email-input"
                      fullWidth={true}
                      inputProps={{ style: { textTransform: "lowercase" } }}
                      value={this.state.email}
                      placeholder="sample@mail.com"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.onEmailChange(e.target.value)
                      }
                      name="email_input"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailOutlinedIcon style={webStyle.EmailOutlinedIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small style={webStyle.errorStyle}> {this.state.errors.email}
                    </small>
                  </Box>

                  <Button
                    type="submit"
                    fullWidth
                    style={{ textTransform: "none", ...webStyle.SignupFormSubmitStyle }}
                    onClick={this.sentEmailSubmit}
                    data-test-id="emailsubmit"
                    disabled={this.state.loadingSignin ? true : false}
                  >
                    {this.state.loaderButtonDisable ? <CircularProgress /> : "Request Reset Link"}
                  </Button>
                  <Box
                    style={webStyle.landingToAnotherPage}
                  >
                    <Typography 
                      style={{ ...webStyle.textStyleInquiry, fontFamily: '' }}>
                      Back to
                    </Typography>
                    <Typography data-test-id="navigate" onClick={this.navigateToLogin}><span style={{ ...webStyle.linkStyle, marginLeft: '5px', cursor: 'pointer' }}>Login</span></Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} data-test-id="errorClose" onClose={this.handleCloseAlert1} >
            <Alert 
              severity="error">
              {this.state.forgetPasswordAPIError}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }} open={this.state.snackbarMailSuccess} autoHideDuration={4000} data-test-id="successClose" onClose={this.handleCloseAlert} >
            <Alert severity="success"> {this.state.forgetPasswordAPIError}
            </Alert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    padding: 16,
    marginRight: "auto",
    width: "100%",
    marginLeft: "auto",
    maxWidth: 650,
    backgroundColor: "#fff"
  },
  containerWeb: {
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
    maxWidth: 650
  },
  countryCodeSelector: {
    flex: 3,
    textAlign: "left",
    textAlignVertical: "center",
    marginTop: 20,
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    width: "80%",
    zIndex: -1,
    alignItems: "center",
  },

  flexContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    width: "100%"
  },

  headline: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },

  webInput: {
    width: "100%",
    marginTop: 20,
    zIndex: -1
  },

  inputAfterCountryCode: {
    zIndex: -1,
    width: "100%",
  },

  mobileInput: {
    alignItems: "stretch",
    flexDirection: "column",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    borderRadius: 2,
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    includeFontPadding: true
  },

  codeInput: {
    width: "30%",
    marginTop: 20,
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    color: "#6200EE",
    fontWeight: "bold",
    fontSize: 32,
  },

  stepText: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 32,
    marginVertical: 8
  },

  emailText: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 16,
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderColor: "#767676",
    borderRadius: 2,
    borderWidth: 1,
    marginTop: 20,
    minHeight: 40,
    textAlignVertical: "center",
    fontSize: 18,
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  }
});
