import React from "react";

import {

    // Customizable Area Start
    Container,
    Box,
    Button,
    Typography,
    Grid,
    TextField,
    InputLabel,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Modal,

    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AppWithoutDrawer from "../../../components/src/AppWithoutDrawer.web";
import Sidebarmenu from "../../../components/src/Sidebarmenu.web";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import HorizontalLine from "../../../components/src/HorizontalLine.web"

import "./CreateTicket.css"
import TicketFileUpload from "../../../components/src/TicketFileUpload.web";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Autocomplete from '@material-ui/lab/Autocomplete';


interface SubCategoryCustomField {
    name: string
    depends_on_choice: boolean | null
    required: boolean
    compulsory_on_completed: boolean
    choices: SubCategoryChoice[]
    compulsory_on_move: boolean
    type: string
    id: number
    categories: SubCategoryCategory[]
    visible_to_staff_only: boolean
}

interface SubCategoryChoice {
    text: string
    id: number
    dependant_fields: string[]
  }
  
  interface SubCategoryCategory {
    category: number
    order: number
  }


const theme = createTheme({

    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


const StyledTextField = withStyles({
    root: {
        fontFamily: "Montserrat",
        fontSize: '16',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: "Montserrat",
            fontSize: '16',
            fontWeight: 400,
            width: '222.98px',
        },
        "& .Mui-disabled": {
            color: "currentColor",
        },
        "& .MuiInputBase-input.MuiInput-input": {
            fontFamily: "Montserrat",
        }
    }
})((props: any) => <TextField {...props} />);
const GetErrorStyle = (props: any) => {
    const { stateType } = props;
    return (
        <Box>
            {
                stateType
                    ? <small style={webStyle.errorStyle}>{stateType}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
            }
        </Box>
    )
}


// Customizable Area End

import Ticketsystem2Controller, {
    Props,
    configJSON,
} from "./Ticketsystem2Controller";

class CreateTickets extends Ticketsystem2Controller {
    popoverAnchor: Element | ((element: Element) => Element) | null | undefined;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End 
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AppWithoutDrawer navigation={this.props.navigation} />
                    <Sidebarmenu activeKey="touch" subActiveKey="ticket" />



                    <ThemeProvider theme={theme}>
                         <Container maxWidth={false}>

                            <Grid container style={{ display: 'block' }}>
                                {/* ---------------------- drawer ------------------------- */}



                                {/* ---------------------- drawer Content ------------------------- */}
                                <Grid item>
                                    <Container

                                        maxWidth={false}
                                    >

                                        {/* ---------------------- MAIN CONTENT OF FILE START ------------------------- */}

                                        <Container maxWidth={false} style={{ margin: '0 auto', width: '100%' }}>
                                            <Box style={{ marginTop: '80px' }}>
                                                <Typography className='welcomeText' style={webStyle.welcomeTextStyle} >Create New Ticket </Typography>
                                            </Box>
                                            <Grid container style={{ display: 'flex', marginBottom: "1rem" }}>
                                                <HorizontalLine />
                                            </Grid>
                                            <Container maxWidth={false} style={webStyle.mainWrapper}>
                                                <Container maxWidth={false} style={webStyle.GridWrapper}>
                                                    <Typography style={webStyle.mainheader}>Ticket Details</Typography>


                                                    {/* ------------------------ inner contents --------------------------     */}
                                                    <Grid container style={{ padding: '2em', display: 'flex', flexDirection: 'column' }}>

                                                        <Grid item xs={12} md={5}>
                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketCategory} </InputLabel>
                                                                <StyledTextField
                                                                    data-testId="TicketCategory"
                                                                    select
                                                                    required
                                                                    InputLabelProps={{ shrink: false }}
                                                                    fullWidth={true}
                                                                    name='ticketCategory'
                                                                    value={this.state.ticketCategory.name}
                                                                    onChange={this.handleChangeTicketCategory}

                                                                >
                                                                    <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>

                                                                    {
                                                                        this.state.ticketCategoryDataSelect.length > 0 && this.state.ticketCategoryDataSelect?.map((item: any) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={item?.id}
                                                                                    value={item?.name}

                                                                                    selected={item.value == this.state.ticketCategory.name} 
                                                                                    >
                                                                                    <span style={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: "bold", letterSpacing: "0.1px" }}> {item?.name}</span>
                                                                                </MenuItem>
                                                                            )
                                                                        })

                                                                    }

                                                                </StyledTextField>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.ticketCategory} style={{marginTop:"-1rem"}} />
                                                            </Box>

                                                            {this.state.subCategory.length > 0 &&
                                                                this.state.subCategory.map((item: SubCategoryCustomField) => {
                                                                    return (
                                                                        <Box style={webStyle.inputBoxStyle}>
                                                                            <InputLabel style={webStyle.inputSelectLabelStyle}>{item.name}* </InputLabel>
                                                                            <StyledTextField
                                                                                data-testId="TicketCategory"
                                                                                select
                                                                                required
                                                                                InputLabelProps={{ shrink: false }}
                                                                                fullWidth={true}
                                                                                name='ticketSubCategory'
                                                                                value={this.setSubCategoryValue(item.id)}
                                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeTicketSubCategory(event, item.id)}

                                                                            >
                                                                                <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Select</span></MenuItem>
                                                                                {
                                                                                    item.choices.length > 0 && item.choices?.map((Data: SubCategoryChoice) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                key={Data.id}
                                                                                                value={Data.text}
                                                                                            >
                                                                                                <span style={{ fontFamily: "Montserrat", fontSize: "16px", fontWeight: "bold", letterSpacing: "0.1px" }}> {Data.text}</span>
                                                                                            </MenuItem>
                                                                                        )
                                                                                    })

                                                                                }

                                                                            </StyledTextField>
                                                                            {this.state.subCategoryErrors.includes(item.id) && (
                                                                                <GetErrorStyle stateType={this.state.ticketErrors.subCategory} />
                                                                            )}
                                                                            <GetErrorStyle />
                                                                        </Box>
                                                                    )
                                                                })
                                                            }


                                                        </Grid>

                                                        {/* ------------------------------------------Type of concerns------------------------------------------ */}




                                                        {/* ------------------------------------------Programs------------------------------------------ */}

                                                        {(this.state.ticketCategory.name != "choose" && this.state.ticketCategory.id == 36) &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>Program*</InputLabel>
                                                                    <Autocomplete
                                                                        data-testId="programType"
                                                                        value={this.state.academicProgramAutoCompleteValue}
                                                                        onChange={(e: any, newValue: any) => { this.setState({ academicProgramAutoCompleteValue: newValue }) }}
                                                                        options={this.state.ticketProgram}
                                                                        getOptionLabel={this.handleGetsOptionLabel}
                                                                        renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                                                        inputValue={this.state.academicProgram.text}
                                                                        onInputChange={(e: any, newInputValue: any) => { this.handlerChangeacademicProgram(e, newInputValue) }}
                                                                    />
                                                                        <GetErrorStyle stateType={this.state.ticketErrors.academicProgram} />
                                                                </Box>
                                                            

                                                            </Grid>
                                                        }

                                                        {/* ------------------------------------------------------------------------------------ */}

                                                        {/* ------------------------------------------Name of Student*------------------------------------------ */}
                                                        {(this.state.ticketCategory.id == 48 || this.state.ticketCategory.id == 25) &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketNameOfStudent} </InputLabel>
                                                                    <StyledTextField
                                                                        data-testId="ticketNameOfStudent"
                                                                        placeholder="Enter Name"
                                                                        required
                                                                        fullWidth={true}
                                                                        name='ticketNameOfStudent'
                                                                        value={this.state.ticketNameOfStudent}
                                                                        onChange={this.handlerChangeticketNameOfStudent}
                                                                        onFocus={this.hideError}
                                                                    >
                                                                    </StyledTextField>
                                                                    <GetErrorStyle stateType={this.state.ticketErrors.ticketNameOfStudent} />
                                                                </Box>
                                                             

                                                            </Grid>
                                                        }
                                                        {/* ------------------------------------------------------------------------------------ */}


                                                        {/* ------------------------------------------Records and documents other fields start------------------------------------------ */}
                                                        {this.state.ticketCategory.id == 25 &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>Hub*</InputLabel>
                                                                    <Autocomplete
                                                                        data-testId="hubType"
                                                                        value={this.state.hubAutoCompleteValue}
                                                                        onChange={(e: any, newValue: any) => { this.setState({ hubAutoCompleteValue: newValue }) }}
                                                                        options={this.state.recordsHGPHHubs}
                                                                        getOptionLabel={this.handleGetsOptionLabel}
                                                                        renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                                                        inputValue={this.state.hubRecords.text}
                                                                        onInputChange={(e: any, newInputValue: any) => { this.handlerChangeHubRecords(e, newInputValue) }}
                                                                    />
                                                                    <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The HG Learning Hub where your child is enrolled in.</Typography>
                                                                    <GetErrorStyle stateType={this.state.ticketErrors.hubRecords} />
                                                                </Box>
                                                             

                                                            </Grid>
                                                        }

                                                        {this.state.ticketCategory.id == 25 &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>Type of Document*</InputLabel>
                                                                    <Autocomplete
                                                                        data-testId="DocType"
                                                                        value={this.state.typeOfDocAutoCompleteValue}
                                                                        onChange={this.handleAutocompleteChange}
                                                                        options={this.state.recordsoncerns}
                                                                        getOptionLabel={this.handleGetsOptionLabel}
                                                                        renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                                                        inputValue={this.state.typeOfDocRecords.text}
                                                                        onInputChange={(e: any, newInputValue: any) => { this.handlerChangeTypeOfDocRecords(e, newInputValue) }}
                                                                    />
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.typeOfDocRecords} />

                                                            </Grid>
                                                        }
                                                        {this.state.ticketCategory.id == 25 &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>School Year*</InputLabel>
                                                                    <Autocomplete
                                                                        data-testId="SchoolYearType"
                                                                        value={this.state.schoolYearAutoCompleteValue}
                                                                        onChange={(e: any, newValue: any) => { this.setState({ schoolYearAutoCompleteValue: newValue }) }}
                                                                        options={this.state.recordsSchoolYear}
                                                                        getOptionLabel={this.handleGetsOptionLabel}
                                                                        renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                                                        inputValue={this.state.schoolYearRecords.text}
                                                                        onInputChange={(e: any, newInputValue: any) => { this.handlerChangeSchoolYearRecords(e, newInputValue) }}
                                                                    />
                                                                    <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The academic year for your requested document.</Typography>
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.schoolYearRecords} />

                                                            </Grid>
                                                        }
                                                        {this.state.ticketCategory.id == 25 &&
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>Partner School*</InputLabel>
                                                                    <Autocomplete
                                                                        data-testId="PartnerSchoolType"
                                                                        value={this.state.partnerSchoolAutoCompleteValue}
                                                                        onChange={this.handleChangePartnerSchoolAutoCompleteValue}
                                                                        options={this.state.recordsPartnerSchool}
                                                                        getOptionLabel={this.handleGetsOptionLabel}
                                                                        renderInput={(params) => < StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='academicProgram' InputLabelProps={{ shrink: false }} />}
                                                                        inputValue={this.state.partnerSchoolRecords.text}
                                                                        onInputChange={(e: any, newInputValue: any) => { this.handlerChangePartnerSchoolRecords(e, newInputValue) }}
                                                                    />
                                                                    <Typography style={{ ...webStyle.inputSelectLabelStyle, marginTop: "10px" }}>The school that provides the accreditation for your homeschooling.</Typography>
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.partnerSchoolRecords} />

                                                            </Grid>
                                                        }

                                                        {/* -----------------------------------------Records and documents other fields End------------------------------------------- */}



                                                        <Grid item xs={12} md={5}>
                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketSubject} </InputLabel>
                                                                <StyledTextField
                                                                    data-testId="ticketSubject"
                                                                    placeholder="Type here"
                                                                    required
                                                                    fullWidth={true}
                                                                    name='ticketSubject'
                                                                    value={this.state.ticketSubject}
                                                                    onChange={(e: any) => { this.setState({ ticketSubject: e.target.value }) }}
                                                                    onFocus={this.hideError}
                                                                // value={this.state.ticketSubject}
                                                                // onChange={this.handleChangeticketSubject} 
                                                                // onFocus={this.hideError}
                                                                >

                                                                </StyledTextField>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.ticketSubject} />
                                                            </Box>
                                                          

                                                        </Grid>
                                                        <Grid item xs={12} >
                                                            <Box>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketMessage} </InputLabel>



                                                                <Box style={
                                                                    {
                                                                        borderRadius: '15px',
                                                                        border: '1px solid #e2e2ea',
                                                                    }
                                                                }>
                                                                    <Editor
                                                                        editorState={this.state.editorState}
                                                                        editorStyle={webStyle.editorClassStyle}
                                                                        onEditorStateChange={this.onEditorStateChange}
                                                                        placeholder="Enter your message here…"
                                                                        toolbar={{
                                                                            options: ['link', 'embedded', 'inline', 'fontFamily', 'colorPicker', 'fontSize', 'list', 'textAlign', 'remove', 'history'],
                                                                            inline: {
                                                                                inDropdown: false,
                                                                                options: ['bold', 'italic', 'underline'],
                                                                            },
                                                                            list: {
                                                                                inDropdown: false,
                                                                                options: ['unordered', 'ordered'],
                                                                            },
                                                                            textAlign: { inDropdown: true },
                                                                            link: { inDropdown: false },
                                                                            history: { inDropdown: true },
                                                                            fontFamily: {
                                                                                options: ['Arial', 'Georgia', 'Impact','Montserrat', 'Tahoma', 'Times New Roman', 'Verdana', 'Poppins'],
                                                                                className: undefined,
                                                                                component: undefined,
                                                                                dropdownClassName: undefined,
                                                                            },
                                                                          
                                                                        }}
                                                                    />

                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.editorState} />
                                                            </Box>

                                                            <Grid item xs={12} style={{ marginTop: '1em' }}>
                                                                <Button variant="contained"
                                                                    data-testId="Cancel"
                                                                    style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCC }}
                                                                >Add CC
                                                                </Button>
                                                                <Button variant="contained"
                                                                    data-testId="Cancel"
                                                                    style={{ textAlign: "center", textTransform: "none",marginLeft: '20px', ...webStyle.btnTicketCC }}
                                                                >Add BCC
                                                                </Button>
                                                            </Grid>
                                                            <GetErrorStyle />

                                                        </Grid>
                                                        {/* ---add Bcc and cc---- */}

                                                        <Grid item xs={12} >
                                                            <Box style={webStyle.inputBoxStyle}>
                                                                <TicketFileUpload uploadFiles={this.uploadFiles}
                                                                    ticketFile={this.state.ticketFile.filenm}
                                                                />
                                                                <GetErrorStyle />
                                                            </Box>

                                                            <Grid item xs={12} md={5}>
                                                                <Box style={{marginBottom: '1.5rem'}}>
                                                                    <InputLabel style={webStyle.headingContactStyle}>{configJSON.contactDetails} </InputLabel>
                                                                </Box>
                                                            </Grid>

                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketFullName} </InputLabel>
                                                                    <StyledTextField
                                                                        data-testId="ticketFullName"
                                                                        required
                                                                        fullWidth={true}
                                                                        name='ticketFullName'
                                                                        value={localStorage.getItem("username")}
                                                                        disabled
                                                                    // readonly
                                                                    >
                                                                    </StyledTextField>
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.ticketFullName} />
                                                            </Grid>
                                                            <Grid item xs={12} md={5}>
                                                                <Box style={webStyle.inputBoxStyle}>
                                                                    <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketEmail} </InputLabel>
                                                                    <StyledTextField
                                                                        data-testId="ticketEmail"
                                                                        placeholder="Type here"
                                                                        required
                                                                        disabled
                                                                        fullWidth={true}
                                                                        name='ticketEmail'
                                                                        value={localStorage.getItem("emailState")}

                                                                    >
                                                                    </StyledTextField>
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.emailState} />
                                                            </Grid>
                                                            <Grid item xs={12} md={5}>
                                                                <InputLabel style={webStyle.inputSelectLabelStyle}>{configJSON.ticketPhoneNumber} </InputLabel>
                                                                <Box style={{ ...webStyle.inputBoxStyle, display: 'flex', alignItems: 'end' }}>
                                                                    <StyledTextField
                                                                        testidentity="contactNumber"
                                                                        style={{ width: '80px', marginTop: '-15px' }}
                                                                        InputLabelProps={{ shrink: false }}
                                                                        name='countryCodeSelected'
                                                                        disabled
                                                                        value={`+ ${this.state.AccountCountryCode}`}
                                                                    >
                                                                    </StyledTextField>
                                                                        <StyledTextField
                                                                            placeholder="Type here"
                                                                            data-testId="ticketPhoneNumber"
                                                                            style={{ marginLeft: '20px' }}
                                                                            required
                                                                            disabled
                                                                            fullWidth={true}
                                                                            name='ticketPhoneNumber'
                                                                            value={localStorage.getItem("phoneNumber")}

                                                                        >
                                                                        </StyledTextField>
                                                                </Box>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.phoneNumber} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.4rem" }}>
                                                                    <div>
                                                                        <Checkbox
                                                                            data-testId="ticketConsent"
                                                                            style={{ color: '#757575', borderRadius: "8px" }}
                                                                            size="small"
                                                                            checked={this.state.ticketConsent}
                                                                            onChange={this.handlerChangeticketConsent}
                                                                        />

                                                                    </div>

                                                                    <div style={{
                                                                        color: "#44444f",
                                                                        fontFamily: "Montserrat",
                                                                        fontSize: "14px",
                                                                        fontWeight: 400,
                                                                        letterSpacing: "0.0875px"

                                                                    }}>
                                                                        {configJSON.ticketConsent}
                                                                    </div>
                                                                </div>
                                                                <GetErrorStyle stateType={this.state.ticketErrors.ticketConsent} />
                                                            </Grid>

                                                            <Grid item xs={12} style={{ marginTop: '1em', textAlign: "right" }}>
                                                                <Button variant="contained"
                                                                    data-testId="Cancel"
                                                                    style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCancel }}
                                                                    onClick={this.handleCancelTicket}
                                                                >Cancel
                                                                </Button>
                                                                <Button data-testId="submitBtnId" variant="contained" style={{ textAlign: "center", textTransform: "none", ...webStyle.btnTicketCreate }}
                                                                    onClick={this.handleCreateTicket}
                                                                > 
                                                                {this.state.createTicketLoader ? <CircularProgress /> : "Create New Ticket"}
                                                                </Button>
                                                            </Grid>

                                                            <Modal
                                                                open={this.state.updateOpenOne}
                                                            >
                                                                <Box style={webStyle.modalStyle}>
                                                                    <button style={webStyle.cancelButton as React.CSSProperties} data-testId="clost-btn" autoFocus onClick={this.handleModelCloseOnCross}>
                                                                        <CloseOutlinedIcon fontSize="small" />
                                                                    </button>
                                                                    <Box style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                                        <Typography align='center' style={{ ...webStyle.Successfully, textAlign: "justify" }}>
                                                                            Thank you for submitting this ticket. Anticipate a copy of this request to be sent to your email with a Reference Number.</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Modal>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </Container>


                                        </Container>

                                        {/* ---------------------- MAIN CONTENT OF FILE END ------------------------- */}

                                    </Container>
                                </Grid>
                            </Grid>



                        </Container>
                    </ThemeProvider>

                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {

    menuItemHover: {
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        },
    },
    modalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '520px',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
    },
    Successfully: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '15px',
        fontWeight: 594,
        width: '368px',



    },
    mainhoriWrapper: {
        background: "#ececf1",
        width: "100%",
        height: "0px",
        border: "1px solid #ececf1",
        marginBottom: "1rem"
    },
    boxCancelIcon: {
        paddingTop: '2px',
        borderRadius: '50px',
        color: '#fff',
        backgroundColor: '#B98ED6',
        width: '32px',
        height: '32px'
    },

    welcomeTextStyle: {
        fontFamily: "Montserrat",
        fontSize: '24px',
        fontWeight: 600,
    },

    btnAddStyle: {
        width: '132px',
        height: '52px',
        borderRadius: '10px',
        backgroundColor: '#e4e4e4',
        border: "none",
        color: '#757575',
        fontFamily: "Montserrat",
        fontSize: ' 16px',
        fontWeight: 500,
        backgroundBlendMode: 'normal',
        margin: '1.5em 1.5em 1.5em 0',
    },
    disabledButton: {
        background: "red",
    },
    btnTicketCancel: {
        width: "150px",
        height: "52px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#b9b9b9",
        borderRadius: "10px",
        marginLeft: '1em',
    },
    btnTicketCC: {
        height: "52px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#b9b9b9",
        borderRadius: "10px",
        padding: '0 30px'
    },
    btnTicketCreate: {
        width: "225px",
        height: "52px",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
        color: "#fafafb",
        backgroundColor: "#fab03d",
        borderRadius: "10px",
        marginLeft: '1em',
    },

    mainWrapper: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'center',
        boxShadow: 'rgba(68, 68, 79, 0.1) 0px 5px 15px',
        padding: '0em',
    },
    GridWrapper: {
        borderRadius: "15px",
        background: "#ffffff",
        border: "1px solid #f1f1f5",
        paddingTop: '1em'
    },
    mainheader: {
        color: " #171725",
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: 600,
    },
    inputBoxStyle: {
        marginBottom: '1rem'
    },

    inputSelectLabelStyle: {
        color: '#757575',
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 400,
        marginBottom: '1rem',
    },
    headingContactStyle: {
        color: '#171725',
        fontFamily: "Montserrat",
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: '1em',
    },
    errorStyle: {
        padding: '5px 0',
        color: 'red',
        fontFamily: "Montserrat",
        fontSize: ' 12px',
    },
    formlabelStyle: {
        display: 'block',
        marginTop: '2em',
        color: '#757575',
        fontFamily: "Montserrat",
        fontSize: '14px',
        fontWeight: 300
    },
    editorClassStyle: {

        height: '250px',
        overflowy: 'auto',
        padding: '15px',
        backgroundColor: "#f9f9f9",
        backgroundBlendMode: "normal",
        overflow: "hidden"
    },
    cancelButton: {
        position: "absolute",
        top: "-13px",
        background: "rgb(185, 142, 214)",
        color: "white",
        right: "-13px",
        borderRadius: '50%',
        border: 'none',
        cursor: 'pointer',  
        padding: '5px'
      },

};
export default CreateTickets
// Customizable Area End
