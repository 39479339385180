import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isEmail, phoneValidate } from "../../../framework/src/Utilities";
const worldMapData = require('city-state-country');
const Country: any = require('country-state-city').Country;
const City: any = require('country-state-city').City;
import { pwd, txt } from "../../../components/src/asset";

export interface IDItem {
  label: string,
  value: string,
  sortName?: string
}

export interface ICountryData {
  id: number;
  sortname: string,
  name: string,
  phoneCode: number,
  phoneCodeSet :string
}

export interface ICityData {
  id: string,
  name: string,
  country_id: string
}
interface ICity {
  name : string ;
}
interface IReasonData {
  id: string,
  type: string,
  attributes: {
    reason: string,
  }
}

interface IBarangayData {
  id: string,
  type: string,
  attributes: {
    barangay_name: string,
  }
}

export interface IErrorData {
  [x: string]: string,
}
interface ICountries {
  name : string , 
  phonecode : string , 
  isoCode : string
}

const CHANNELS: IDItem[] = [
  {
    label: "Homeschool Global website",
    value: "Homeschool Global website"
  },
  {
    label: "Google/Internet",
    value: "Google/Internet"
  },
  {
    label: "Family/Friends",
    value: "Family/Friends"
  },
  {
    label: "Facebook",
    value: "Facebook"
  },
  {
    label: "Church",
    value: "Church"
  },
  {
    label: "Others",
    value: "Others"
  },
]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string,
  lastName: string,
  countryCode: string,
  phoneNumber: string,
  emailAddress: string,
  residenceCountry: string,
  residenceCity:string,
 
  residenceBarangayId: string,
  homeSchoolingReason: string,
  learnAboutUs: string,
  password: string,
  confirmPassword: string,
  errors: IErrorData,
  countryList: IDItem[],
  countryPhoneCodeList: IDItem[],
  citiesList: IDItem[]
  reasonList: IDItem[]
  barangayList: IDItem[],
  barangayPlaceholder: string,
  passInputState:any;
  passState:boolean;
  residenceBarangay: string,
  snackbarOpenFalse: boolean;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarOpenError: boolean;
  loadingSignup: boolean;
  passwordState: boolean;
  passwordInputState: string;
  getAllCountries : ICountries[]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId = "";
  getReasonCallId = "";
  getBarangayCallId = "";
  getProvincesCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      countryCode: "+63",
      phoneNumber: "",
      emailAddress: "",
      residenceCountry: "",
      residenceCity: "",
      residenceBarangayId: "",
      homeSchoolingReason: "choose",
      learnAboutUs: "choose",
      password: "",
      confirmPassword: "",
      errors: {},
      countryList: [],
      countryPhoneCodeList: [],
      citiesList: [],
      reasonList: [],
      barangayList: [],
      barangayPlaceholder: configJSON.dropdownPlaceHolder,
      passInputState:'password',
      passState:true,
      residenceBarangay: "choose",
      snackbarOpenFalse: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarOpenError: false,
      loadingSignup: false,
      passwordInputState: pwd,
      passwordState: true , 
      getAllCountries : []
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(!apiRequestCallId){
        return
      }
      if (apiRequestCallId === this.getReasonCallId) {
        this.onProcessReasonResponse(message)
      }
      if (apiRequestCallId === this.createAccountApiCallId) {
        this.onProcessCreateAccountResponse(message)
      }
      if (apiRequestCallId === this.getBarangayCallId) {
        this.onProcessGetBarangayResponse(message)
      }
      if  (apiRequestCallId === this.getProvincesCallId) {
        this.onProcessGetProviceResponse(message)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isStringNullOrBlank(strin: string) {
    return strin === null || strin.length === 0;
  }

  onChangeFirstName = (input: string) => {
    this.setState(state => {
      return {
        firstName: input,
        errors: {
          ...state.errors,
          "firstName": ""
        }
      }
    })
  }

  onChangeLastName = (input: string) => {
    this.setState(state => {
      return {
        lastName: input,
        errors: {
          ...state.errors,
          "lastName": ""
        }
      }
    })
  }

  onChangePhoneNumber = (input: string) => {
    this.setState(state => {
      return {
        phoneNumber: input,
        errors: {
          ...state.errors,
          "phoneNumber": ""
        }
      }
    })
  }

  onChangeEmailAddress = (input: string) => {
    this.setState(state => {
      return {
        emailAddress: input,
        errors: {
          ...state.errors,
          "emailAddress": ""
        }
      }
    })
  }

  onCountryCodeSelected = (item: {label: string, value: string}) => {
    this.setState({
      countryCode: `+${item.value}`,
    })
  }


  onResidenceCountrySelected = (newInputValue : any) => {
    this.setState(state => {
      return {
        residenceCountry: newInputValue.name,
        residenceCity: "",
        barangayList: [],
        residenceBarangayId: "",
        errors: {
          ...state.errors,
          "residenceCountry": "",
          "residenceBarangay" : "",
          "residenceCity": ""
        }
      }
    }, () => {
      this.getCityList(newInputValue.isoCode)
    })
  }
  autocompleteContry=(e: any, newInputValue: any)=>{
     this.setState({
      residenceCountry: newInputValue, 
      residenceCity: "",               
      citiesList: [],             
    });
  
  
    const selectedCountry = this.state.getAllCountries.find(
      item => item.name === newInputValue
    );
  
    if (selectedCountry) {
      let cityData: ICity[] = [];
      City.getCitiesOfCountry(selectedCountry.isoCode).map((city: ICity) => {
        cityData.push({
          name: city.name,
        });
      });
  
     
      const uniqueCities = Array.from(new Map(cityData.map(city => [city.name, city])).values());
  
      
      if (uniqueCities.length === 0) {
        this.setState({ citiesList: [{ label: 'No City found', value: 'No City found' }] });
      } else {
        this.setState({ citiesList: uniqueCities.map((city: ICity) => ({ label: city.name, value: city.name })) });
      }
    } 
    else {
      this.setState({
        residenceCity: "",
        citiesList: []
      });
    }
  }
  autocompleteCity=(e: any, newInputValue: any)=>{
    this.setState({ residenceCity: newInputValue })
  }
  onCountryCodeSelectedWeb = (e: React.ChangeEvent<{}>,
    newValue: IDItem) => {
    this.setState({
      countryCode: `+${newValue.value}`,
    })
  }

  onResidenceCitySelected = (item: {label: string, value: string}) => {
    this.setState(state => {
      return {
        residenceCity: item.value,
        residenceBarangayId: "",
        errors: {
          ...state.errors,
          "residenceCity": "" ,
          "residenceBarangay" : "",
        }
      }
    }, () => {
      this.onGetBarangayList(item.label)
    })
  }

  onBarangaySelected = (item: string) => {
    this.setState({
      residenceBarangayId: item,
      residenceBarangay: item,
      errors: {
        ...this.state.errors,
        "residenceBarangay": ""
      }
    })
  }

  onHomeSchoolingReasonSelected = (item: string) => {
    this.setState(state => {
      return {
        homeSchoolingReason: item,
        errors: {
          ...state.errors,
          "homeSchoolingReason": ""
        }
      }
    })
  }

  onLearnAboutUsSelected = (item: string) => {
    this.setState(state => {
      return {
        learnAboutUs: item,
        errors: {
          ...state.errors,
          "learnAboutUs": ""
        }
      }
    })
  }

  onChangePassword = (input: string) => {
    this.setState(state => {
      return {
        password: input,
        errors: {
          ...state.errors,
          "password": ""
        }
      }
    })
  }

  onChangeConfirmPassword = (input: string) => {
    this.setState(state => {
      return {
        confirmPassword: input,
        errors: {
          ...state.errors,
          "confirmPassword": ""
        }
      }
    })
  }
  


  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpen: false })
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  };
  
  handleClose1 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenError: false })
  };  

  handleClose2 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpenFalse: false })
  };

  onSubmitAccountSetting = () => {
    if (this.onValidate()) {
      this.onCallCreateAccountApi()
    }
  }
  cityChange= (newInputValue:string) => {
    this.setState({ residenceCity: newInputValue })
  }

  formValidation = () => {
    const regEx = /^[A-Za-z]{1,25}$/
    let tempError: IErrorData = {}
    if(this.isStringNullOrBlank(this.state.firstName)){
      tempError["firstName"] = "First name must not be empty"
    } else if(!this.state.firstName.match(regEx)){
      tempError["firstName"] = "First name must contain only letters and be fewer than 25 characters"
    }
    if(this.state.lastName.length == 0){
      tempError["lastName"] = "Last name must not be empty"
    } else if(!this.state.lastName.match(regEx)){
      tempError["lastName"] = "Last name must contain only letters and be fewer than 25 characters"
    }

    if(this.isStringNullOrBlank(this.state.residenceCountry)){
      tempError["residenceCountry"] = `Country of residence must not be empty`
    }
    if(this.isStringNullOrBlank(this.state.residenceCity)){
      tempError["residenceCity"] = `City of residence must not be empty`
    }
    if(this.state.homeSchoolingReason === "choose"){
      tempError["homeSchoolingReason"] = `${configJSON.labelSchoolingReason} must not be empty`
    }
    if(this.state.learnAboutUs === "choose"){
      tempError["learnAboutUs"] = `This field must not be empty`
    }
    return tempError;
  }

  onValidate = () => {
    let tempError: IErrorData = {}
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\/{}\\[?:";'|<>,.~@#$%^&*()_+])[A-Za-z\d!\/{}\\[?:";'|<>,.~@#$%^&*()_+]{8,}$/
    tempError = this.formValidation();
    const validatePhoneNumberField = (value: string) => {
      if (!isValidPhoneNumber(value)) {        
        return {
          status: false,
          message: `Please enter a valid phone number with 6 to 11 digits`
        };
      }
      return { status: true, message: "" };
    };
    const isValidPhoneNumber = (number:string) => {
      const regexPhoneNumber = /^\d+$/;
      const minLength = 6;
      const maxLength = 11;
      return regexPhoneNumber.test(number) && number.length >= minLength && number.length <= maxLength;
    };
    const phoneCheck = validatePhoneNumberField(this.state.phoneNumber)

    const validateEmailField = (value: string) => {
      if (!validateEmail(value)) {        
        return {
          status: false,
          message: `Please provide a valid email address`
        };
      }
      return { status: true, message: "" };
    };
    const validateEmail = (email:string) => {
      const regexEmail =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return email.trim() && regexEmail.test(email) ? true : false;
    };
    const email = validateEmailField(this.state.emailAddress)
    
    if(!phoneCheck.status){
      tempError["phoneNumber"] = phoneCheck.message
    }
    if(!email.status){
      tempError["emailAddress"] = email.message
    }
    
    if(this.isStringNullOrBlank(this.state.password)){
      tempError["password"] =  `Password must not be empty`
    } else if (!passwordRegx.test(this.state.password)) {
      tempError["password"] = `Password must contain min 8 characters with both upper and lower cases, atleast one digit and one special character required`
    }
    if(this.state.residenceCountry == 'Philippines'){
      if(this.isStringNullOrBlank(this.state.residenceBarangayId)){
          tempError["residenceBarangay"] = `Barangay must not be empty`
      }
    }
    this.setState({
      errors: tempError
    })
    const check = JSON.stringify(tempError) == JSON.stringify({})
    return check
  }

  onSignUpCall = () => {
    const resultCheck = this.onValidate()
    if(resultCheck){
      this.onCallCreateAccountApi()
    }
  }

  getCountriesList = () => {
    Country.getAllCountries().map((item: any) => {
      this.state.getAllCountries.push({
        name: item.name,
        phonecode: item.phonecode,
        isoCode: item.isoCode
      })
    })
    const countriesResult: ICountryData[] = worldMapData.getAllCountries()
    const countriesListItem: IDItem[] = countriesResult.map(item => {
      return {
        label: item.name,
        value: item.name
      }
    })
    const sorted = [...countriesResult].sort(this.sortPhoneCode)
   
    const phoneCodeSet = new Set<string>(); // Set to store unique phone codes
    
    const phoneCodelistItem: IDItem[] = sorted.reduce((acc: IDItem[], item) => {
      // Check if phone code is already added
      if (!phoneCodeSet.has(item.phoneCode.toString())) {
        phoneCodeSet.add(item.phoneCode.toString()); // Add phone code to set
        acc.push({
          label: item.sortname,
          value: item.phoneCode.toString(),
          sortName: item.sortname.toString()
        });
      }
      return acc;
    }, []);
  
    this.setState({
      countryList: countriesListItem,
      countryPhoneCodeList: phoneCodelistItem,
      barangayList: [],
    })
  }

  sortPhoneCode = (itemA: ICountryData, itemB: ICountryData) => {
    return itemA.phoneCode - itemB.phoneCode
  }

  getCityList = (countryCode: string) => {
    if(countryCode == "PH"){
      this.onGetPhillipinesProvinces()
    } 
  }

  getSchoolingReason = () => {
    this.callApi(configJSON.homeSchoolingReasonsEndPoint, configJSON.homeSchoolingReasonsMethod)
  }

  onProcessReasonResponse = (message: Message) => {
    const reasonResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const reasonErrorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(reasonErrorReponse){
      this.parseApiCatchErrorResponse(reasonErrorReponse);
      return;
    }
    if (reasonResponseJson) {
      const reasonList: IReasonData[] = reasonResponseJson.data
      const converted: IDItem[] = reasonList.map(item => {
        return {
          label: item.attributes.reason,
          value: item.id
        }
      })
      this.setState({
        reasonList: converted
      })
    }
  }

  onProcessCreateAccountResponse = (message: Message) => {
    const { navigation } = this.props;
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(errorReponse){
      this.parseApiCatchErrorResponse(errorReponse);
      return;
    }
    if (responseJson.errors) {
      this.setState({ loadingSignup:false});
      if (responseJson.errors[0].account) {
        this.setState({ snackbarOpenFalse: true, snackbarMessage: responseJson.errors[0].account });
      } else {
        this.setState({ snackbarOpenFalse: true, snackbarMessage: "Something went wrong " });
      }
    } else {
      this.setState({ loadingSignup:false});
      this.setState({ snackbarOpen: true, snackbarMessage: "Congratulations! Account created successfully. "});
    }
  }

  onCallCreateAccountApi = () => {
    this.setState({loadingSignup: true});
    const body = {
      data : {
        type: "email_account",
        attributes: {
          email: this.state.emailAddress,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          phone_number: this.state.phoneNumber,
          country_code: this.state.countryCode,
          country: this.state.residenceCountry,
          city: this.state.residenceCity,
          barangay_id: this.state.residenceBarangayId,
          home_schooling_reason_id: this.state.homeSchoolingReason,
          informed_about_us: this.state.learnAboutUs,
          inquiry_from: "signup"
        }
      }
    }
    this.callApi(configJSON.accountsAPiEndPoint, configJSON.accountsApiCreateMethod, body)
  }

  onGetPhillipinesProvinces = () => {
    this.callApi(configJSON.provinceApiEndpoint, configJSON.provinceApiMethod)
  }

  onProcessGetProviceResponse = (message: Message) => {
    const provinceResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const barangayErrorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(barangayErrorReponse){
      this.parseApiCatchErrorResponse(barangayErrorReponse);
      return;
    }
    if (provinceResponseJson) {
      const listData: string[] = provinceResponseJson.data
      if(listData){
        const converted: IDItem[] = listData.map(item => {
          return {
            label: item,
            value: item
          }
        })
        this.setState({
          citiesList: converted
        })
      } else {
        this.setState({
          citiesList: [],
        })
      }
    }
  }

  onGetBarangayList = (city: string) => {
    this.callApi(`${configJSON.barangayApiEndpoint}?city=${city}`, configJSON.barangayApiMethod)
  }

  onProcessGetBarangayResponse = (message: Message) => {
    const barangayResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const barangayErrorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if(barangayErrorReponse){
      this.parseApiCatchErrorResponse(barangayErrorReponse);
      return;
    }
    if (barangayResponseJson) {
      const listData: IBarangayData[] = barangayResponseJson.data
      if(listData){
        const converted: IDItem[] = listData.map(item => {
          return {
            label: item.attributes.barangay_name,
            value: item.id
          }
        })
        this.setState({
          barangayList: converted
        })
      } else {
        this.setState({
          barangayList: [],
          barangayPlaceholder: "No barangay"
        })
      }
    }
  }

  async componentDidMount() {
    this.getCountriesList()
    this.getSchoolingReason()
  }

  callApi = async (
      endpoint: string, 
      method: "GET" | "POST",
      body?: Object
    ) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const apiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(endpoint == configJSON.homeSchoolingReasonsEndPoint){
      this.getReasonCallId = apiMessage.messageId;
    }
    if(endpoint == configJSON.accountsAPiEndPoint){
      this.createAccountApiCallId = apiMessage.messageId
    }
    if(endpoint == configJSON.provinceApiEndpoint){
      this.getProvincesCallId = apiMessage.messageId
    }
    if(endpoint.includes(configJSON.barangayApiEndpoint)){
      this.getBarangayCallId = apiMessage.messageId
    }

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && apiMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(body)
		);
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }

  changePasswordState = () => {
    if (this.state.passwordState) {
      this.setState({ passwordState: false })
      this.setState({ passwordInputState: txt })
    }
    else {
      this.setState({ passwordState: true })
      this.setState({ passwordInputState: pwd })
    }
  }
  // Customizable Area End
}
