// Customizable Area Start
import React from "react";
import {
    Box,
    InputLabel,
    TextField,
    Grid,
    Switch,
    createStyles,
    Theme,
} from "@material-ui/core";

import { purple } from '@material-ui/core/colors';

import { withStyles } from "@material-ui/core/styles";

export const StyledTextField = withStyles({
    root: {
        fontFamily: "Montserrat",
        fontSize: '16',
        "& .MuiFilledInput-root": {
            backgroundColor: "white",
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "white",
            "@media (hover: none)": {
                backgroundColor: "white",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "white",
        },
        "& input::placeholder": {
            fontFamily: "Montserrat",
            fontSize: '16',
            fontWeight: 400,
            
        },
        "& .MuiInputBase-input.MuiInput-input": {
            fontFamily: "Montserrat",
        }
    }
})((props: any) => <TextField {...props} />);

export const GetErrorStyle = (props: any) => {
    const { stateType } = props;
    return (
        <Box style={webStyle.FormAlignmentStyles}>
            {
                stateType
                    ? <small style={webStyle.errorStyle1}>{stateType}</small>
                    : <small style={{ visibility: 'hidden' }}>""</small>
            }
        </Box>
    )
};

export const GetLabelTextField = (props: any) => {
    const { labelTitle, firstStepDataValue, compareValue, textPlaceholder, textName, textValue, textOnChange, textOnFocus, errorText } = props;
    return (
        firstStepDataValue === compareValue ?
            <Grid item xs={12} md={6}>
                <InputLabel style={webStyle.inputLabelStyle1}>{labelTitle}</InputLabel>
                <StyledTextField
                    placeholder={textPlaceholder}
                    fullWidth
                    name={textName}
                    value={textValue}
                    onChange={textOnChange}
                    onFocus={textOnFocus}
                />
                <GetErrorStyle stateType={errorText} />
            </Grid> : null
    )
}


export const getOrConditionValue = (condition1: any, condition2:any) =>{
    return !!(condition1 || condition2)
}


export const ToggleSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 35,
      height: 15,
      padding:0,
      display: 'flex',
      paddingBottom:3,
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(21px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: purple[500],
          borderColor: purple[500],
        },
      },
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 8,
      opacity: 1,
      backgroundColor: theme.palette.grey[500],
    },
    checked: {},
  }),
)(Switch);


const webStyle = {
    FormAlignmentStyles: {
        marginTop: '3px'
    },
    errorStyle1: {
        color: 'red',
        fontFamily: "Montserrat",
        fontSize: ' 14px',
    },
    inputLabelStyle1: {
        marginBottom: '10px',
        height: '22px',
        color: "#757575",
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22px',
    },
}

// Customizable Area End