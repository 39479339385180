import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  TextField,
  MenuItem,
  Snackbar,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { imgConnect } from './assets'
import { Link } from "react-router-dom";
import { cityAutoComplete } from "../../../components/src/asset";

const theme = createTheme({
  palette: {
    primary: {
      main: "#nnn",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiMenu: {
      paper: {
        maxHeight: 'calc(50% - 96px)',
      },
    },
    MuiIconButton: {
      root: {
        padding: 0
      },
    },
  },
});


const StyledTextField = withStyles({
  root: {
    maxWidth: '100%',
    fontSize: '16',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontSize: '16',
      fontWeight: 400,
      width: '222.98px',
      height: '19px',
      color: '#44444f'

    },
    "& .MuiInputBase-input.MuiInput-input": {
        fontFamily: "Montserrat",
    }
  }
})((props: any) => <TextField {...props} />);

import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlined from '@material-ui/icons/LockOutlined';

export interface IDItem {
  label: string,
  value: string,
}

export const configJSON = require("./config");
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }


  renderError(error: string) {
    if (error) {
      return <small style={webStyle.errorStyle}>{error}</small>;
    }
    return null;
  }
 

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ margin: '20px auto', width: '95%' }}>

          <Grid container style={webStyle.mainWrapperRegistration}>
            <Grid item xs={12} sm={6} lg={6} style={webStyle.mainInnerWrapperRegistration}>
              <Box sx={webStyle.leftWrapperRegistration}>
                <Box sx={webStyle.innerLeftStyleReg}>
                  <Box style={{ width: '50%' }}>
                    <img
                      alt="connect"
                      src={imgConnect}
                      style={webStyle.imgStyle}
                    />
                  </Box>
                  <Typography variant="h5" component={'h5'} style={webStyle.textStyleLeft} >
                    Step into the exiting world of <span style={{ color: '#171725' }}>learning better </span>
                    and <span style={{ color: '#171725' }}>pursuing what matters,</span> the HG Way
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} style={webStyle.mainInnerWrapperRegistration}>
              <Box sx={webStyle.rightWrapperReg}>
                <Box sx={webStyle.innerRightStyle}>
                  <Typography variant="h6" component={'h6'} style={webStyle.textStyleRight} >
                    Signup below to get started
                  </Typography>

                  <Box style={{ marginTop: '30px' }}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.labelFirstName} </InputLabel>
                    <StyledTextField
                      placeholder="Enter First Name"
                      fullWidth={true}
                      name='firstName'
                      data-testid="firstname"
                      value={this.state.firstName}
                      onChange={(nameFirst: React.ChangeEvent<HTMLInputElement>) =>
                        this.onChangeFirstName(nameFirst.target.value)
                      }
                    />
                  </Box>

                  <Box>
                    {this.renderError(this.state.errors.firstName)}
                  </Box>

                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.lastName} </InputLabel>
                    <StyledTextField
                      placeholder="Enter Last Name"
                      fullWidth={true}
                      name='lastName'
                      data-testid="lastname"
                      value={this.state.lastName}
                      onChange={(lastName: React.ChangeEvent<HTMLInputElement>) =>
                        this.onChangeLastName(lastName.target.value)
                      }
                    />
                  </Box>
                  <Box>
                  {this.renderError(this.state.errors.lastName)}
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> Contact Number *</InputLabel>
                    <Box style={{ display: 'flex', alignItems: 'end' }}>
                      {cityAutoComplete(this.onCountryCodeSelectedWeb,this.state.countryPhoneCodeList,this.state.countryCode)}
                      <StyledTextField
                        testID="input-phone-number"
                        inputProps={{ maxlength: 11 }}
                        style={{ marginLeft: '20px' }}
                        placeholder="1234567890"
                        fullWidth={true}
                        name='phone'
                        value={this.state.phoneNumber}
                        onChange={(numberPhone: any) => this.onChangePhoneNumber(numberPhone.target.value)}
                      />
                    </Box>
                  </Box>
                  <Box>
                  {this.renderError(this.state.errors.phoneNumber)}
                    
                  </Box>

                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}> {configJSON.labelPassword} </InputLabel>
                    <StyledTextField
                      testID="input-password"
                      type={this.state.passwordInputState}
                      placeholder="Enter Password"
                      fullWidth={true}
                      name='password'
                      value={this.state.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end"  >
                            <IconButton onClick={this.changePasswordState} style={{padding: '0px'}}> {
                              this.state.passwordState ?
                              <LockOutlined style={{...webStyle.EmailOutlinedIcon}} /> 
                              :<LockOpenIcon style={webStyle.EmailOutlinedIcon} /> 
                            }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(passwordEnter: React.ChangeEvent<HTMLInputElement>) => this.onChangePassword(passwordEnter.target.value)}
                    />
                  </Box>
                  <Box>
                  {this.renderError(this.state.errors.password)}
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputLabelStyle}>{configJSON.labelEmail} </InputLabel>
                    <StyledTextField
                      testID="input-email"
                      placeholder="email@mail.com"
                      fullWidth={true}
                      name='email'
                      value={this.state.emailAddress}
                      onChange={(email: React.ChangeEvent<HTMLInputElement>) => this.onChangeEmailAddress(email.target.value)}
                    />
                  </Box>
                  <Box>
                  {this.renderError(this.state.errors.emailAddress)}
                    
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> Country of Residence  *</InputLabel>

                    <Autocomplete
                      data-testid="autocomplete-country"
                      onChange={(event : any , newValue: any) => { newValue && this.onResidenceCountrySelected(newValue) }}
                      options={this.state.getAllCountries}
                      getOptionLabel={(option: any) => option.name}
                      getOptionSelected={(option: any, value: any) =>
                        option.name === value.name}
                      renderInput={(params: any) =>
                        <StyledTextField {...params} placeholder='Please Select' fullWidth={true} name='country'
                          InputLabelProps={{ shrink: false }} />
                      }
                      inputValue={this.state.residenceCountry}
                      onInputChange={this.autocompleteContry}
                    />
                  </Box>
                 
                  <Box>
                  {this.renderError(this.state.errors.residenceCountry)}
                  </Box>

                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> City/Province of Residence *</InputLabel>

                    <Autocomplete
                      data-testid="autocomplete-city"
                      onChange={(e: any, newValue: any) => { newValue && this.onResidenceCitySelected(newValue)  }}
                      options={this.state.citiesList}
                      getOptionLabel={(option: any) => option.value}
                      getOptionSelected={(option: any, value: any) => option.value === value.value}
                      renderInput={(params: any) =>
                        < StyledTextField {...params}
                          placeholder='Please Select' fullWidth={true}
                          name='city' InputLabelProps={{ shrink: false }}
                        />}
                      inputValue={this.state.residenceCity}
                      onInputChange={this.autocompleteCity}
                    />
                  </Box>

                  <Box>
                  {this.renderError(this.state.errors.residenceCity)}
                  </Box>
                  {this.state.residenceCountry != "" && this.state.barangayList?.length > 0 && this.state.residenceCity != "" ?
                    <>
                      <Box style={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.inputLabelStyle}>Barangay  *</InputLabel>
                        <StyledTextField
                          testID="input-barangay"
                          select
                          color="darkgrey"
                          fullWidth
                          name="barangay"
                          InputLabelProps={{ shrink: false }}
                          value={this.state.residenceBarangay}
                          onChange={(barangay: any) => this.onBarangaySelected(barangay.target.value)}
                        >
                          <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                          {
                            this.state.barangayList.map((item: any) => {
                              return (

                                <MenuItem
                                  key={item?.value}
                                  value={item.value}
                                >
                                  {item?.label}
                                </MenuItem>
                              )

                            })

                          }
                        </StyledTextField>
                      </Box>
                    </>
                    : null}

                  <Box>
                  {this.state.residenceCountry != "" && this.state.residenceCity != "" && this.renderError(this.state.errors.residenceBarangay)}
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}> Reason for Considering Homeschooling  *</InputLabel>
                    <StyledTextField
                      select
                      testID="input-reason"
                      style={{ width: '42vw' }}
                      InputLabelProps={{ shrink: false }}
                      fullWidth={true}
                      name='reasonForHomeSchooling'
                      value={this.state.homeSchoolingReason}
                      onChange={(homeSchool: any) => this.onHomeSchoolingReasonSelected(homeSchool.target.value)}
                    >
                      <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                      {
                        this.state.reasonList?.map((item: any, index: number) => (
                          <MenuItem
                            style={{ width: '550px', wordWrap: 'break-word', whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}

                            key={item?.id + index}
                            value={item.value}
                          >
                            {item?.label}
                          </MenuItem>
                        ))
                      }
                    </StyledTextField>
                  </Box>
                  <Box>
                  {this.renderError(this.state.errors.homeSchoolingReason)}
                  </Box>
                  <Box style={webStyle.inputBoxStyle}>
                    <InputLabel style={webStyle.inputSelectLabelStyle}>How did you learn about us?  *</InputLabel>
                    <StyledTextField
                      testID="input-learn-about"
                      select
                      InputLabelProps={{ shrink: false }}
                      fullWidth={true}
                      style={{ maxWidth: '100%', width: '100%', overflow: 'hidden' }}
                      name='learnAboutUs'
                      value={this.state.learnAboutUs}
                      onChange={(learnAboutUs: { target: { label: string; value: string; }; }) => this.onLearnAboutUsSelected(learnAboutUs.target.value)}
                    >
                      <MenuItem style={{ color: '#ABABB0' }} disabled value="choose"> <span style={{ color: "#ABABB0" }}>Please Select</span></MenuItem>
                      <MenuItem value="Homeschool Global Website">Homeschool Global Website</MenuItem>
                      <MenuItem value="Google/Internet">Google/Internet</MenuItem>
                      <MenuItem value="Family/Friends">Family/Friends</MenuItem>
                      <MenuItem value="Facebook">Facebook</MenuItem>
                      <MenuItem value="Church">Church</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </StyledTextField>
                  </Box>
                  <Box>
                    {this.renderError(this.state.errors.learnAboutUs)}
                  </Box>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpen} autoHideDuration={6000} onClose={this.handleClose}
                  data-testid="snackHandleClose" >
                    <Alert severity="success">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }} open={this.state.snackbarOpenFalse} autoHideDuration={4000} onClose={this.handleClose2} >
                    <Alert severity="error">
                      {this.state.snackbarMessage}
                    </Alert>
                  </Snackbar>
                  <Button
                    type='submit'
                    fullWidth
                    disableElevation
                    disableRipple
                    data-testid="formsubmitbtn"
                    style={{
                      textTransform: 'none', ...webStyle.signupFormSubmit
                    }}
                    onClick={this.onSubmitAccountSetting}
                  >
                    {this.state.loadingSignup ? <CircularProgress /> : "Submit"}
                  </Button>
                  <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                    <Typography style={webStyle.textStyleInquiry}>
                      Inquired before or an existing HG Parent?
                      <span style={webStyle.btnStyleInquiry}>
                        <Link to="EmailAccountLoginBlock"> <span style={webStyle.linkStyle}>Login</span></Link>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  signupFormSubmit: {
    width: "100%",
    height: "52px",
    marginTop: "40px",
    border: "none",
    borderRadius: "10px",
    backgroundColor: " #fab03d",
    color: '#fafafb',
    fontFamily: "Montserrat",
    fontSize: ' 16px',
    fontWeight: 600,
  },
  mainWrapperRegistration: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    height: '100%',
    justifyContent: 'center',
    boxShadow: '3px -5px 40px rgba(205,205,212,0.1)',
    margin: "20px"
  },

  mainInnerWrapperRegistration: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  leftWrapperRegistration: {
    flex: 1,
    border: '1px solid  #979797',
    borderTopLeftRadius: '50px',
    borderBottomLeftRadius: '50px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginbottom: '20px'
  },

  innerLeftStyleReg: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '90px',
    textAlign: 'center',
  },

  rightWrapperReg: {
    flex: 1,
    border: '1px solid  #9167AC',
    borderTopRightRadius: '50px',
    borderBottomRightRadius: '50px',
    backgroundColor: '#fbfbfb',
    marginbottom: '20px'
  },
  innerRightStyle: {
    paddingLeft: '50px',
    paddingRight: '50px',
  },

  imgStyle: {
    width: '100%',
  },

  textStyleRight: {
    marginTop: '30px',
    marginBottom: '19px',
    height: '42px',
    color: '#171725',
    fontFamily: "Montserrat",
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: '42px',
    letterSpacing: '0.12px',
  },

  inputLabelStyle: {
    marginBottom: '10px',
    width: '220px',
    height: '22px',
    color: '#696974',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
  },
  inputSelectLabelStyle: {
    height: '22px',
    color: '#696974',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
  },

  inputBoxStyle: {
    marginTop: '14px',
  },
  textStyleInquiry: {
    marginTop: '25px',
    color: '#696974',
    fontFamily: "Montserrat",
    fontSize: ' 16px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0.1px',
  },
  textStyleLeft: {
    marginTop: '22px',
    color: '#757575',
    fontFamily: "Montserrat",
    fontSize: ' 25px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0.1px',
    alignText: 'center',
  },

  errorStyle: {
    color: 'red',
    fontFamily: "Montserrat",
    fontSize: ' 12px',
  },
  linkStyle: {
    color: 'black',
    textDecoration: 'underline',
    textDecorationColor: 'black',
    fontWeight: 600
  },
  EmailOutlinedIcon: {
    color: "#b5b5be"
  },
  btnStyleInquiry: {
    marginTop: "25px",
    textDecoration: "underline",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: 500,
    lineHeight: "19px",
  },
  hidden: {
    visibility: "hidden"
  }
};
// Customizable Area End