Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.accountDetailsEndPoint = "bx_block_customisableuserprofiles/profiles/6"
  exports.accountChangePasswordEndPoint = "/change_password"
  exports.apiProvincesCallMethodType = "GET";
  exports.apiProvincesCallUrl="provinces";
  exports.provinceApiMethodOne = "GET";

  exports.barangayApiMethodOne = "GET";
  exports.barangayApiEndpointOne = "get_barangays"

  exports.postStudent="bx_block_customisableuserprofiles/profiles/6"
  exports.homeSchoolingReasonsEndPoints = "home_schooling_reasons"
  exports.homeSchoolingReasonsMethods = "GET";
  exports.dropdownPlaceHolderOne = "Please select"
  exports.test = 'test'

  
 
 


  
// Customizable Area End
