import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import { createTheme, makeStyles} from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';

export const CustomFormTheme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiInput: {
            input: {
                color: "black",
            }
        }
    },
});


export const StepperTheme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            //contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        // MuiStepIcon: {
        //     root: {
        //         height: '48px',
        //         width: '48px',
        //         textAlign: 'center',
        //         color: '#fff',
        //         border: '2px solid #9167ac',
        //         borderRadius: '50px',
        //         '&$completed': {
        //             color: '#9167ac',
        //             height: '48px',
        //             width: '48px',
        //             border: 'none',
        //         },
        //         '&$active': {
        //             color: '#b39ddb',
        //             border: 'none',
        //         },

        //         '&$line': {
        //             borderColor: 'red',
        //         },

        //     },

        //     text: {
        //         fill: 'grey',
        //         fontFamily: "'Poppins-Medium', sans-serif",
        //     },
        // },
        MuiStepConnector: {
            alternativeLabel: {
                top: '70px',
                left: 'calc(-50% + 27px)',
                right: 'calc(50% + 27px)',
            },
            active: {
                '& $line': {
                  borderColor: '#9167ac',
                },
              },
              completed: {
                '& $line': {
                  borderColor: '#9167ac',
                },
              },
            line: {
                borderColor: '#eaeaf0',
                height:3,
            }
        },
        MuiStepLabel:{
          label:{
            fontWeight:500,
            paddingBottom:'8px',
            fontSize:'14px',
            width:'7.5rem',
          },
        },
        MuiPaper: {
            root: {
                backgroundColor: 'white',
            }
        },
       
    },
});

const customStepIconStyles = makeStyles({
    root: {
        height: '48px',
        width: '48px',
        textAlign: 'center',
        
        border: '2px solid #9167ac',
        backgroundColor: 'white',
        zIndex: 1,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        // '&$completed': {
        //     color: '#9167ac',
        //     height: '48px',
        //     width: '48px',
        //     border: 'none',
        // },
        // '&$active': {
        //     color: 'white',
        //     border: 'none',
        // },

        // '&$line': {
        //     borderColor: 'red',
        // },

    },

    text: {
        fill: 'grey',
        fontFamily: "Montserrat",
        fontWeight:600,
    },
    active: {
      backgroundColor: '#9167ac',
      color:'white'
    },
    completed: {
      backgroundColor: '#9167ac',
      color:'white'
    },
  });

  export function customStepIcon(props :any) {
    const classes = customStepIconStyles();
    const { active, completed } = props;
  
    const icons : { [index: string]: React.ReactElement }= {
      1: <Typography style={{ color: active || completed ? 'white' : 'black' }}>1</Typography>,
      2: <Typography style={{ color: active || completed ? 'white' : 'black' }}>2</Typography>,
      3: <Typography style={{ color: active || completed ? 'white' : 'black' }}>3</Typography>,
      4: <Typography style={{ color: active || completed ? 'white' : 'black' }}>4</Typography>,
      5: <Typography style={{ color: active || completed ? 'white' : 'black' }}>5</Typography>,
      6: <Typography style={{ color: active || completed ? 'white' : 'black' }}>6</Typography>,
      7: <Typography style={{ color: active || completed ? 'white' : 'black' }}>7</Typography>,
    };
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }
  
  customStepIcon.propTypes = {
    active: PropTypes.bool,

    completed: PropTypes.bool,

    icon: PropTypes.node,
  };


